<template>
  <ClientOnly fallback-tag="span" fallback="Please wait...">
    <v-app id="inspire">
      <system-bar />
      <v-snackbar v-model="website.snackbar.visible" :color="website.snackbar.color" location="top" variant="tonal" :multi-line="true">
        <v-row>
          <v-col class="flex-grow-0 flex-shrink-0 align-self-center">
            <v-icon size="x-large" :icon="website.snackbar.icon" />
          </v-col>
          <v-col class="flex-column">
            <div>{{ website.snackbar.title }}</div>
            <div class="text-xs">
              {{ website.snackbar.text }}
            </div>
          </v-col>
        </v-row>
      </v-snackbar>
      <v-navigation-drawer color="grey-darken-4" rail>
        <NuxtLink to="/main">
          <img :src="logo" class="object-contain rounded-full max-w-12 max-h-12 my-4 mx-1"></img>
        </NuxtLink>
        <v-divider :thickness="2" class="mx-1 my-2 border-opacity-50" />

        <NuxtLink to="/hq/">
          <v-avatar :image="hq" class="mx-2 my-2" />
        </NuxtLink>

        <NuxtLink to="/refinery">
          <v-avatar :image="refinery" class="mx-2 my-2" />
        </NuxtLink>

        <v-divider :thickness="2" class="mx-1 my-2 border-opacity-50" />
        <logout />
      </v-navigation-drawer>

      <v-navigation-drawer width="244" color="grey-darken-3 text-grey-lighten-5">
        <v-btn
          class="text-h4 text-center py-4"
          height="100"
          width="100%"
          variant="text"
          to="/hq/"
          color="grey-darken-3"
        >
          <span class="text-white">
            Refinery
          </span>
        </v-btn>

        <v-list>
          <v-list-item
            v-for="(item, index) in menu"
            :key="index"
            :title="item.title"
            link
            nuxt
            :to="item.to"
          />
        </v-list>
      </v-navigation-drawer>

      <v-app-bar class="px-3" color="grey-darken-2" height="72" flat>
        <v-responsive max-width="156">
          <div class="text-xl text-grey-400">
            {{ pageTitle }}
          </div>
        </v-responsive>
        <v-spacer />
      </v-app-bar>

      <v-main>
        <NuxtErrorBoundary>
          <NuxtPage />

          <template #error="{ error }">
            <div class="inline-flex gap-x-2 align-center my-4">
              <code class="text-red-600">{{ error }}</code>
              <v-btn color="primary" @click="resetError(error)">
                reset
              </v-btn>
            </div>
          </template>
        </NuxtErrorBoundary>
      </v-main>

      <v-navigation-drawer location="right" color="grey-darken-2 text-grey-lighten-5">
        <v-list density="compact">
          <v-list-item
            v-for="(item, index) in computedActionMenu"
            :key="index"
            link
            nuxt
            :to="item.title == 'Dashboard' ? item.to : ''"
          >
            <template #title>
              <div class="text-sm font-medium">
                {{ item.title }}
              </div>
            </template>
            <v-list density="compact">
              <v-list-item
                v-for="(item, index) in item.submenu"
                :key="index"
                link
                nuxt
                :to="item.to"
                class="ma-0"
              >
                <template #title>
                  <div class="text-xs">
                    {{ item.title }}
                  </div>
                </template>
              </v-list-item>
            </v-list>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-app>
  </ClientOnly>
</template>

<script setup>

import { computed } from 'vue'
import logo from '~/assets/img/logo.jpeg'
import hq from '~/assets/img/hq.jpg'
import refinery from '~/assets/img/refinery.jpeg'
import { useMenuRefineryAction } from '~/composables/useMenuRefineryAction'

const website = useWebsiteStore()
const route = useRoute()

const menu = useMenuRefinery()
const subMenu = useMenuRefineryAction()

const computedActionMenu = computed(() => {
  try {
    const currentModule = route?.name.split('-')[1]
    const filteredSubMenu = subMenu?.filter(item => item.key === currentModule)
    return filteredSubMenu[0]?.menu
  } catch (error) {
    throw createError({
      statusCode: 500,
      statusMessage: error
    })
  }
})

const pageTitle = computed(() => {
  try {
    const names = route.name.split('-')
    const name = names[1]
    if (name === 'hr') {
      return 'HR'
    } else {
      const firstChr = name?.charAt(0)?.toUpperCase()
      const restChr = name?.slice(1)
      const combine = firstChr + restChr
      return combine
    }
  } catch (error) {
    throw createError({
      statusCode: 500,
      statusMessage: error
    })
  }
})

const resetError = (error) => {
  error.value = null
}

</script>
