export const useMenuRefineryAction = () => {
  const menu = [
    {
      key: 'report',
      menu: [
        {
          title: 'Report',
          to: '/refinery/report/',
          submenu: [
            { title: 'Ullage', to: '/refinery/report/ullage' },
            { title: 'Lab', to: '/refinery/report/lab' },
            { title: 'Production', to: '/refinery/report/production' },
            { title: 'WeightBridge', to: '/refinery/report/weightBridge' },
            { title: 'Palm Kernel', to: '/refinery/report/palmKernel' },
            { title: 'Utilities', to: '/refinery/report/utilities' },
            { title: 'Maintenance', to: '/refinery/report/maintenance' },
            { title: 'Running Hours', to: '/refinery/report/runningHours' },
            { title: 'Stock Movement', to: '/refinery/report/stockMovement' }
          ]
        }
      ]
    },
    {
      key: 'purchaser',
      menu: [

        {
          title: 'List',
          to: '/refinery/purchaser/list',
          submenu: []
        }
      ]
    },
    {
      key: 'factorymanager',
      menu: [

        {
          title: 'Approval',
          to: '',
          submenu: []
        }

      ]
    },
    {
      key: 'coss',
      menu: [
        {
          title: 'Summary',
          to: 'refinery/coss',
          submenu: [
            { title: 'Sales Report', to: '/refinery/coss/report/sales_report' },
            { title: 'Packaging Report', to: '/refinery/coss/report/packaging_report' },
            { title: 'Stock Report - COSS', to: '/refinery/coss/report/stock_report' },
            { title: 'Stock Report - Packed COSS', to: '/refinery/coss/report/stock_report_packed' },
            // { title: 'Simulated Delivery', to: '/refinery/coss/report/simulate_delivery' },
          ]
        },

        {
          title: 'Sale',
          to: 'refinery/coss',
          submenu: [
            { title: 'Buyer', to: '/refinery/coss/sale/coss_buyer' },
            { title: 'List', to: '/refinery/coss/sale/coss_list' },
            { title: 'Schedule', to: '/refinery/coss/sale/coss_schedule' },
            { title: 'Sell Application', to: '/refinery/coss/sale/coss_sell' },
            { title: 'Approval', to: '/refinery/coss/sale/coss_sell_approval' },
            { title: 'Invoice & Delivery order', to: '/refinery/coss/sale/coss_invoice_delivery_order' },
            { title: 'After Sale Return', to: '/refinery/coss/sale/coss_burst' },
            // { title: 'Summary', to: '/refinery/coss/coss_summary' }

          ]
        },
        {
          title: 'Buy',
          to: 'refinery/coss',
          submenu: [
            { title: 'Supplier', to: '/refinery/coss/coss_supplier' },
            { title: 'Received - COSS', to: '/refinery/coss/buy/coss_receiving' },
          ]
        },
        {
          title: 'Packaging',
          to: 'refinery/coss',
          submenu: [
            { title: 'Packaging', to: '/refinery/coss/packaging/coss_packaging_daily_report' },
            { title: 'Buffer Tank', to: '/refinery/coss/packaging/coss_buffer_tank' },
            { title: 'Stock Loss', to: '/refinery/coss/packaging/coss_stock_loss' },

          ]
        },

      ]
    }

  ]
  return menu
}
